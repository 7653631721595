import { isAddress } from '@ethersproject/address'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'wagmi'

import { useQueryKeys } from '@app/utils/cacheKeyFactory'

import { ChainId } from '../useChainId'
import { useChainName } from '../useChainName'

// type TChain = 'eth' | 'bsc' | 'klay' | 'matic'
interface Params {
  chain: string
  account: string
}
interface IFnProps {
  chainId: ChainId
  account: string
  refresh?: number | undefined
}
export interface IResultItem {
  address: string
  name: string
  symbol: string
  logoUrl: string
  price: number
  amount: number
  rawAmount: number
}
const _url = `${process.env.NEXT_PUBLIC_BASE_URL}/rpc/token/list`
const fetchGetTokenList = async (_params: Params) => {
  const query = new URLSearchParams(Object.entries(_params)).toString()
  const url = `${_url}?${query}`
  const result = await fetch(url)
  return result.json<{ data: IResultItem[] }>()
}
const useGetTokenList = ({ account, chainId, refresh }: IFnProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const chainName = useChainName()

  const chain = useMemo(() => {
    if (chainId === ChainId.MAINNET) {
      return 'eth'
    }
    return chainName
  }, [chainId, chainName])

  console.log('🚀 ~ chainName ~ chainName:', chain, chainName, chainId)

  const queryKey = useQueryKeys().getUserTokenList
  const { data, refetch } = useQuery(
    queryKey(account, chain),
    async () => {
      setLoading(true)
      if (!account) return
      try {
        const res = await fetchGetTokenList({
          chain,
          account,
        })
        setLoading(false)

        return res.data.map((item: any) => ({
          address: isAddress(item.id) ? item.id : '0x0000000000000000000000000000000000000000',
          name: item.name,
          symbol: item.symbol,
          logoUrl: item.logo_url,
          price: item.price,
          amount: item.amount,
          rawAmount: item.raw_amount,
          decimals: item.decimals,
        })) as IResultItem[]
      } catch {
        setLoading(false)
        return undefined
      }
    },
    {
      enabled: !!account || !!chain,
    },
  )

  useEffect(() => {
    if (refresh !== -1) {
      refetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh])

  return { data, loading }
}
export default useGetTokenList
